import React from 'react';
import withWidth from '@material-ui/core/withWidth';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { selectCompany, selectFeatures } from 'modules/auth/selectors';
import { setFilteredColumns, setFilters, setPage, setPageSize, setQuickFilters, setSortedColumns, setToggledColumns } from './actions';
import {
	selectFilteredColumns,
	selectFilters,
	selectPage,
	selectPageSize,
	selectQuickFilters,
	selectSortedColumns,
	selectToggledColumns
} from './selectors';

const mapStateToProps = createStructuredSelector({
	company: selectCompany(),
	filters: selectFilters(),
	page: selectPage(),
	pageSize: selectPageSize(),
	quickFilters: selectQuickFilters(),
	sorted: selectSortedColumns(),
	filtered: selectFilteredColumns(),
	toggled: selectToggledColumns(),
	features: selectFeatures()
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			setFilters,
			onPageChange: setPage,
			onPageSizeChange: setPageSize,
			setQuickFilters,
			onSortedChange: setSortedColumns,
			setToggled: setToggledColumns,
			onFilteredChange: setFilteredColumns,
		},
		dispatch
	);


const componentWithFiltering = (WrappedComponent) => (props) => {
	const {
		filters,
		setFilters,
		quickFilters,
		setQuickFilters
	} = props;

	const setFilter = (name, value) => {
		setFilters({ ...filters, [name]: value });
	};

	const removeFilter = (name) => {
		const { [name]: _, ...remainingFilters } = filters;
		setFilters(remainingFilters);
	};

	const toggleFilter = (name) => {
		filters[name] ? removeFilter(name) : setFilter(name, []);
	};

	const toggleQuickFilter = (name) => {
		setQuickFilters(quickFilters.includes(name)
			? quickFilters.filter((filter) => filter !== name)
			: [...quickFilters, name]);
	};

	return (
		<WrappedComponent
			{...props}
			setFilter={setFilter}
			removeFilter={removeFilter}
			toggleFilter={toggleFilter}
			toggleQuickFilter={toggleQuickFilter}
		/>
	);
};


export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withWidth(),
	componentWithFiltering
);


