import api from './api';

export async function getOrders(company = null, orderParams = {}) {
	const response = await api.get(`company/${company || 'me'}/orders`, { params: orderParams });
	return response.data;
}

export async function validateNextSuggestion(id) {
	const response = await api.post(`material/${id}/validateNextSuggestion`);
	return response.data;
}

export async function updateMaterials(body, company = null) {
	const response = await api.post(`company/${company || 'me'}/upload`, body);
	return response.data;
}
