import { materialsEqual, PARAMETERS } from 'modules/scenario';

const safeDiv = (num, denom) => (denom === 0 ? 0 : num / denom);

const calculateSavingsPercentages = (baseCalc) => ({
	supplyChainSavingsPercent: safeDiv(baseCalc.supplyChainSavings, baseCalc.comparisonSupplyChainCost),
	orderSavingsPercent: safeDiv(baseCalc.orderSavings, baseCalc.comparisonSumOrderCost),
	inventorySavingsPercent: safeDiv(baseCalc.inventorySavings, baseCalc.comparisonSumCarryingCost),
	scrapSavingsPercent: safeDiv(baseCalc.scrapSavings, baseCalc.comparisonSumScrapCost),
});

export const addBase = comparison => {
	if (!comparison.scenarios) return comparison;

	const baseMaterialsMap = new Map(comparison.materials.map(m => [m.number, m]));

	const newMaterials = comparison.scenarios[0].materials.map(material => {
		const base = baseMaterialsMap.get(material.number);
		if (!base) return material;

		const edited = !materialsEqual(material, base);
		const savingsPercentages = calculateSavingsPercentages(base.calculation);

		return {
			...material,
			base: {
				...base,
				calculation: {
					...base.calculation,
					...savingsPercentages
				}
			},
			edited
		};
	});

	return {
		...comparison,
		scenarios: [{
			...comparison.scenarios[0],
			materials: newMaterials
		}]
	};
};

export const getEditedColumns = comparison => {
	if (!comparison.scenarios) return [];

	const editedColumns = new Set();

	comparison.scenarios[0].materials.forEach(material => {
		if (material.edited) {
			PARAMETERS.forEach(param => {
				if (material[param] !== material.base[param]) {
					editedColumns.add(param);
				}
			});
		}
	});

	return Array.from(editedColumns);
};
