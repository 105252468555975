import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';

const MenuButton = ({ children, label, ...rest }) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	return (
		<>
			<Button onClick={event => setAnchorEl(event.currentTarget)} endIcon={<ExpandMoreIcon />} {...rest}>
				{label}
			</Button>
			<Menu open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)} anchorEl={anchorEl} getContentAnchorEl={null}>
				{children}
			</Menu>
		</>
	);
};

export default MenuButton;
