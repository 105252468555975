import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Checkbox } from 'components/Menu/ColumnMenu';
import React from 'react';

const Index = ({
	getOptionLabel = value => value,
	label,
	limitTags = 4,
	onChange,
	onClearOptions,
	onSelectAll,
	onToggleOption,
	options = [],
	placeholder,
	selectAllLabel = 'Select/unselect all',
	value = [],
	...rest
}) => {
	const allSelected = options.length === value.length;
	const handleChange = (event, newValue, reason) => {
		if (reason === 'select-option' || reason === 'remove-option') {
			// Handle select all case
			if (newValue.indexOf(selectAllLabel) > -1) {
				const v = allSelected ? [] : options.slice();
				onChange(v);
				onSelectAll && onSelectAll(v);
			} else {
				onChange(newValue);
				onToggleOption && onToggleOption(newValue);
			}
		} else if (reason === 'clear') {
			onChange([]);
			onClearOptions && onClearOptions();
		}
		// onChange(newValue);
	};
	const optionRenderer = (option, { selected }) => (
		<Checkbox checked={option === selectAllLabel ? allSelected : selected} label={getOptionLabel(option)} />
	);
	const inputRenderer = props => <TextField label={label} placeholder={placeholder} {...props} />;

	const filter = createFilterOptions();

	return (
		<Autocomplete
			{...rest}
			disableCloseOnSelect
			filterOptions={(opts, params) => [selectAllLabel, ...filter(opts, params)]}
			getOptionLabel={getOptionLabel}
			limitTags={limitTags}
			multiple
			openOnFocus
			options={options}
			onChange={handleChange}
			renderInput={inputRenderer}
			renderOption={optionRenderer}
			size="small"
			value={value}
		/>
	);
};

export default Index;
