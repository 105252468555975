/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import App, { Fallback } from 'containers/App'; // Import root app
import LanguageProvider from 'containers/LanguageProvider'; // Import Language Provider
import configureStore from './configureStore';
import { translationMessages } from './i18n'; // Import i18n messages
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { StoreProvider } from './contexts/StoreContext';


// Initialize Sentry.io as early as possible
if (process.env.NODE_ENV === 'production') {  //|| true and env variables uncommented to do local testing of sentry
	console.log('Initializing Sentry');
	Sentry.init({
		dsn: process.env.SENTRY_DSN,
		environment: process.env.SENTRY_ENVIRONMENT,
		integrations: [
			Sentry.reactRouterV6BrowserTracingIntegration({
				useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes
			})],
		release: process.env.VERSION,
		// This sets the sample rate to be 10%. You may want this to be 100% while in development and sample at a lower rate in production
		replaysSessionSampleRate: 0.1,
		// If the entire session is not sampled, use the below sample rate to sample sessions when an error occurs.
		replaysOnErrorSampleRate: 1.0,
		// Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
		tracesSampleRate: 1.0
	});
}

// Initialize Google Analytics
if (process.env.GOOGLE_ANALYTICS_ID) {
	ReactGA.initialize(process.env.GOOGLE_ANALYTICS_ID);
	ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
}

if (process.env.GOOGLE_TAG_MANAGER_ID) {
	TagManager.initialize({ gtmId: process.env.GOOGLE_TAG_MANAGER_ID });
}

// Create redux store
const initialState = {};
const store = configureStore(initialState);
const mountNode = document.getElementById('app');

const render = messages => {
	ReactDOM.render(
		<Provider store={store}>
			<StoreProvider>
				<Sentry.ErrorBoundary fallback={Fallback}>
					<LanguageProvider messages={messages}>
						<App />
					</LanguageProvider>
				</Sentry.ErrorBoundary>
			//</StoreProvider>
		</Provider>,
		mountNode
	);
};

if (module.hot) {
	// Hot reloadable React components and translation json files
	// modules.hot.accept does not accept dynamic dependencies,
	// have to be constants at compile-time
	module.hot.accept(['./i18n', 'containers/App'], () => {
		ReactDOM.unmountComponentAtNode(mountNode);
		render(translationMessages);
	});
}

// Intl messages
render(translationMessages);

// Userback
const userback = window.Userback || {};
userback.access_token = process.env.USERBACK_TOKEN;
window.Userback = userback;
(function(d) {
	const s = d.createElement('script');
	s.async = true;
	s.src = 'https://static.userback.io/widget/v1.js';
	(d.head || d.body).appendChild(s);
})(document);

