import React, { useState } from 'react';
import { isWidthUp } from '@material-ui/core/withWidth';
import Check from '@material-ui/icons/Check';
import {
	cell,
	co2Cell,
	co2Footer,
	coloredCurrencyCell,
	coloredPercentCell,
	currencyAverageFooter,
	currencyCell,
	currencyFooter,
	percentCell,
	unitCell
} from 'components/ReactTable';
import tableStyles from 'components/ReactTable/styles.scss';
import { Alerts, makeMaterialColumn, Material } from 'modules/material';
import { camelCaseToTitle } from 'utils/string';
import { sortSAPDate } from 'utils/dates';
import { formatShortQuantity } from 'utils/format';
import { mergeClasses } from 'utils/classHelper';
import XSButton from 'components/button/XSButton';
import styles from './styles.scss';
import { validateNextSuggestion } from 'modules/api2/orders';

const ValidationCell = ({ mrpElement, materialId }) => {
	const [isValidated, setIsValidated] = useState(mrpElement === 'POItemGL');

	const handleValidation = async (e) => {
		e.preventDefault();
		await validateNextSuggestion(materialId);
		setIsValidated(true);
	};

	if (isValidated) {
		return (
			<div className={styles.Opening} title="Next suggestion validated">
				<Check className={styles.Validated} />
			</div>
		);
	}
	// Can be validated - show button
	if (mrpElement === 'PurReqGL') {
		return (
			<div className={styles.Opening}>
				<XSButton
					variant="contained"
					color="primary"
					className={styles.Validate}
					title="Validate next suggestion"
					onClick={handleValidation}
				>
					<Check />
				</XSButton>
			</div>
		);
	}

	// Neither validated nor can be validated - empty cell
	return <div className={styles.Opening} />;

};


const safeDiv = (num, denom) => (denom === 0 ? 0 : num / denom);

const createColumns = ({
												 width,
												 features,
												 totalCO2Transport,
												 totalCO2Warehouse,
												 toggled
											 }) => {
	// Create all column groups with their definitions
	const columnGroups = [
		{
			id: 'alerts',
			header: null,
			columns: [{
				id: 'alertsColumn',
				filterable: false,
				width: 25,
				Cell: ({ original: { planAlerts } }) => (
					<div>
						{planAlerts.negativeAvailableStock ? (
							<span title={Alerts.negativeAvailableStock.label} className={tableStyles.Bullet} style={{ backgroundColor: '#E44600' }} />
						) : null}
						{planAlerts.belowSSAvailableStock ? (
							<span title={Alerts.belowSSAvailableStock.label} className={tableStyles.Bullet} style={{ backgroundColor: '#FFA900' }} />
						) : null}
						{planAlerts.reorderDueToScrap ? (
							<span title={Alerts.reorderDueToScrap.label} className={tableStyles.Bullet} style={{ backgroundColor: '#02A7FF' }} />
						) : null}
					</div>
				)
			}]
		},
		{
			id: 'material',
			header: 'Material',
			columns: [
				makeMaterialColumn(Material.number),
				makeMaterialColumn(Material.name),
				makeMaterialColumn(Material.mrpController),
				makeMaterialColumn(Material.procurementTypeCode),
				makeMaterialColumn(Material.specialProcurementTypeCode),
				makeMaterialColumn(Material.purchasingGroup),
				makeMaterialColumn(Material.mrpType),
				makeMaterialColumn(Material.storageType),
				makeMaterialColumn(Material.storageName),
				makeMaterialColumn(Material.suppliers)
			]
		},
		{
			id: 'transfer',
			header: 'Transfer',
			conditional: true,
			shouldShow: () => true, // Always include transfer columns in definition
			columns: [
				{ accessor: 'plantCode', Header: 'Target ID' },
				{ accessor: 'plantName', Header: 'Target Name' },
				{ accessor: 'transferData.sourceId', Header: 'Source ID' },
				{ accessor: 'transferData.sourceName', Header: 'Source Name' },
				{ accessor: 'transferData.quantity', Header: 'Quantity', Cell: unitCell },
				{ accessor: 'transferData.deliveryDate', Header: 'Delivery Date' },
				{ accessor: 'transferData.pickupDate', Header: 'Pickup Date' },
				{ accessor: 'transferData.transferSavingsBRL', Header: 'Transfer Savings BRL', Cell: currencyCell, Footer: currencyFooter }
			]
		},
		{
			id: 'additional',
			header: 'Additional',
			conditional: true,
			shouldShow: () => features?.orders?.columns?.length > 0,
			columns: (features?.orders?.columns ?? []).map(c => ({
				accessor: c.id,
				Header: camelCaseToTitle(c.id),
				filterable: true,
				...c
			}))
		},
		{
			id: 'parameters',
			header: 'Parameters',
			columns: [
				makeMaterialColumn(Material.orderCost),
				makeMaterialColumn(Material.orderCostWeight),
				makeMaterialColumn(Material.carryingCost),
				makeMaterialColumn(Material.carryingCostWeight),
				makeMaterialColumn(Material.pricePerUnitSingle),
				makeMaterialColumn(Material.endOfYear),
				makeMaterialColumn(Material.horizon),
				makeMaterialColumn(Material.phaseout),
				makeMaterialColumn(Material.phaseoutDuration),
				makeMaterialColumn(Material.currency),
				makeMaterialColumn(Material.strictAboutSafetyStock)
			]
		},
		{
			id: 'orders',
			header: 'Next order',
			headerClassName: tableStyles.OutlinedColumn,
			columns: (() => {
				const orderColumn = { className: tableStyles.OutlinedColumn, headerClassName: tableStyles.OutlinedColumn };
				const dateColumn = {
					...orderColumn,
					sortMethod: sortSAPDate,
					width: isWidthUp('xl', width) ? 105 : 85,
					Cell: ({ value, original }) =>
						original.firstOrder.orderDate === original.calculation.labels[0] ?
							<span style={{ fontWeight: 'bold' }}>{value}</span> :
							<>{value}</>
				};

				const placeOrderColumn = {
					...orderColumn,
					id: 'placeOrder',
					accessor: m => m.firstOrder.mrpElement,
					noMenu: true,
					width: 40,
					Cell: ({ original }) => (
						<ValidationCell
							mrpElement={original.firstOrder.mrpElement}
							materialId={original.id}
						/>
					)
				};

				return [
					{ ...dateColumn, accessor: 'firstOrder.orderDate', Header: 'Opening' },
					{ ...dateColumn, accessor: 'firstOrder.deliveryDate', Header: 'Delivery' },
					{ ...dateColumn, accessor: 'firstOrder.releaseDate', Header: 'Release' },
					{ ...orderColumn, accessor: 'firstOrder.quantity', Header: 'Quantity', Cell: unitCell, minWidth: 75 },
					...(features.material?.autoSave ? [placeOrderColumn] : []),
					{ ...makeMaterialColumn(Material.supplierLeadTime), ...orderColumn },
					{ ...makeMaterialColumn(Material.qualityControlLeadTime), ...orderColumn },
					{
						...orderColumn,
						accessor: m => (m.qualityControlLeadTime || 0) + (m.supplierLeadTime || 0),
						id: 'leadTime',
						Header: 'Total lead time (PD & GR)',
						Cell: cell('days')
					},
					{ ...orderColumn, accessor: 'calculation.firstOrderSAP.mrpElementData', Header: 'SAP PurReq id' },
					{ ...orderColumn, accessor: 'firstOrder.mrpElement', Header: 'MRP element' },
					{ ...makeMaterialColumn(Material.roundingValue), ...orderColumn },
					{ ...makeMaterialColumn(Material.minimumOrderQuantity), ...orderColumn },
					{ ...makeMaterialColumn(Material.maximumOrderQuantity), ...orderColumn },
					{ ...makeMaterialColumn(Material.fixedOrderSize), ...orderColumn }
				];
			})()
		},
		{
			id: 'inventory',
			header: 'Inventory',
			columns: [
				{
					accessor: 'calculation.AvailableInventory[0]',
					id: 'availableInventory',
					Header: 'Available inventory',
					Cell: ({ value, original: { unit, planAlerts } }) => (
						<>
							<div
								className={mergeClasses(
									tableStyles.Number,
									value < 0 ? tableStyles.Negative : planAlerts.belowSSAvailableStock ? tableStyles.Warn : tableStyles.Positive
								)}>
								{formatShortQuantity(value)}
							</div>
							<div className={tableStyles.Unit}>{unit}</div>
						</>
					)
				},
				{
					accessor: 'calculation.DeliveredInventory[0]',
					id: 'deliveredInventory',
					Header: 'Delivered inventory',
					Cell: ({ value, original: { unit, planAlerts } }) => (
						<>
							<div
								className={mergeClasses(
									tableStyles.Number,
									value < 0 ? tableStyles.Negative : planAlerts.belowSSDeliveredStock ? tableStyles.Warn : tableStyles.Positive
								)}>
								{formatShortQuantity(value)}
							</div>
							<div className={tableStyles.Unit}>{unit}</div>
						</>
					)
				},
				{
					id: 'averageInventory',
					accessor: m => m.accountingPrice * m.calculation.AverageInventory,
					Cell: currencyCell,
					Header: 'Average inventory',
					Footer: currencyAverageFooter
				},
				{ ...makeMaterialColumn(Material.safetyStock), accessor: m => m.safetyStock || 0 },
				{ accessor: 'calculation.estimatedCoverage', Header: 'Estimated coverage', Cell: cell('days'), minWidth: 90 },
				makeMaterialColumn(Material.safetyTime),
				makeMaterialColumn(Material.serviceLevel),
				makeMaterialColumn(Material.maxInventory),
				makeMaterialColumn(Material.shelfLife),
				makeMaterialColumn(Material.coverageDays),
				makeMaterialColumn(Material.maxCoverage),
				makeMaterialColumn(Material.strictMaxCoverage)
			]
		},
		{
			id: 'savings',
			header: 'Savings',
			columns: [
				{ accessor: 'calculation.pureSavings', Header: 'Total savings', Cell: coloredCurrencyCell, Footer: currencyFooter },
				{ accessor: 'calculation.supplyChainSavings', Header: 'Supply chain savings', Cell: coloredCurrencyCell, Footer: currencyFooter },
				{ accessor: 'calculation.orderSavings', Header: 'Order savings', Cell: coloredCurrencyCell, Footer: currencyFooter },
				{ accessor: 'calculation.inventorySavings', Header: 'Inventory savings', Cell: coloredCurrencyCell, Footer: currencyFooter },
				{ accessor: 'calculation.scrapSavings', Header: 'Scrap savings', Cell: coloredCurrencyCell, Footer: currencyFooter },
				{ accessor: 'calculation.quantityDiscounts', Header: 'Quantity discounts', Cell: coloredCurrencyCell, Footer: currencyFooter },
				{ accessor: 'calculation.TotalCost', Header: 'Total Cost', Cell: currencyCell, Footer: currencyFooter },
				{ accessor: 'calculation.SumCarryingCost', Header: 'Carrying cost', Cell: currencyCell, Footer: currencyFooter },
				{ accessor: 'calculation.SumOrderCost', Header: 'Order cost', Cell: currencyCell, Footer: currencyFooter },
				{ accessor: 'calculation.SumPurchasingCost', Header: 'Purchasing cost', Cell: currencyCell, Footer: currencyFooter }
			]
		},
		{
			id: 'savingsPercent',
			header: 'Savings %',
			columns: [
				{
					id: 'supplyChainSavingsPercent',
					accessor: m => safeDiv(m.calculation.supplyChainSavings, m.calculation.comparisonSupplyChainCost),
					Header: 'Supply chain savings %',
					Cell: coloredPercentCell
				},
				{
					id: 'orderSavingsPercent',
					accessor: m => safeDiv(m.calculation.orderSavings, m.calculation.comparisonSumOrderCost),
					Header: 'Order savings %',
					Cell: coloredPercentCell
				},
				{
					id: 'inventorySavingsPercent',
					accessor: m => safeDiv(m.calculation.inventorySavings, m.calculation.comparisonSumCarryingCost),
					Header: 'Inventory savings %',
					Cell: coloredPercentCell
				},
				{
					id: 'scrapSavingsPercent',
					accessor: m => safeDiv(m.calculation.scrapSavings, m.calculation.comparisonSumScrapCost),
					Header: 'Scrap savings %',
					Cell: coloredPercentCell
				}
			]
		},
		{
			id: 'co2',
			header: 'CO2',
			columns: [
				{ accessor: 'calculation.CO2', Header: 'Total GHG', Cell: co2Cell, Footer: co2Footer },
				{ accessor: 'calculation.CO2Transport', Header: 'Transport GHG', Cell: co2Cell, Footer: co2Footer },
				{
					accessor: m => m.calculation.CO2Transport / totalCO2Transport,
					id: 'CO2Transport%',
					Header: 'Transport GHG %',
					Cell: percentCell
				},
				{ accessor: 'calculation.CO2Warehouse', Header: 'Warehouse GHG', Cell: co2Cell, Footer: co2Footer, minWidth: 90 },
				{
					accessor: m => m.calculation.CO2Warehouse / totalCO2Warehouse,
					id: 'CO2Warehouse%',
					Header: 'Warehouse GHG %',
					Cell: percentCell,
					minWidth: 90
				},
				makeMaterialColumn(Material.transportMode),
				makeMaterialColumn(Material.warehouse)
			]
		}
	];

	// Set visibility for columns
	const setColumnVisibility = (columns) => {
		columns.forEach(column => {
			column.show = column.id === 'alertsColumn' || toggled.includes(column.id || column.accessor);
		});
		return columns;
	};

	// Build final columns structure
	return columnGroups
		.filter(group => !group.conditional || group.shouldShow())
		.map(group => ({
			Header: group.header,
			columns: setColumnVisibility([...group.columns]),
			...(group.headerClassName ? { headerClassName: group.headerClassName } : {})
		}));

};

export default createColumns;
