import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const DataContext = createContext();

export function DataProvider({ children }) {
	const [searchParams, setSearchParams] = useSearchParams();
	const [data, setData] = useState({
		company: searchParams.get('company'),
		dashboardView: searchParams.get('dashboardView'),
	});

	// Keep URL and context state in sync
	useEffect(() => {
		const companyUrl = searchParams.get('company');
		const viewUrl = searchParams.get('dashboardView');

		setData(prev => ({
			...prev,
			company: companyUrl,
			dashboardView: viewUrl
		}));
	}, [searchParams]);

	const updateDataContext = (key, value) => {
		const newSearchParams = new URLSearchParams(searchParams);

		if (value) {
			newSearchParams.set(key, value);
		} else {
			newSearchParams.delete(key);
		}
		setSearchParams(newSearchParams);

		setData(prev => ({
			...prev,
			[key]: value
		}));
	};

	return (
		<DataContext.Provider
			value={{
				company: data.company,
				dashboardView: data.dashboardView,
				updateDataContext
			}}
		>
			{children}
		</DataContext.Provider>
	);
}

export function useDataContext() {
	const context = useContext(DataContext);
	if (!context) {
		throw new Error('useDataContext must be used within a DataProvider');
	}
	return context;
}
