// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-components-TabPanel-styles-TabPanel{padding:12px}@media only screen and (min-width: 1400px){.app-components-TabPanel-styles-TabPanel{padding:16px}}.app-components-TabPanel-styles-NoOverflow{overflow:auto;height:calc(100vh - 130px)}", "",{"version":3,"sources":["webpack://./app/components/TabPanel/styles.scss","webpack://./app/style/variables.scss"],"names":[],"mappings":"AACA,yCCiFC,YAAA,CAlBA,2CD/DD,yCCmFE,YAAA,CAAA,CD/EF,2CACC,aAAA,CACA,0BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TabPanel": "app-components-TabPanel-styles-TabPanel",
	"NoOverflow": "app-components-TabPanel-styles-NoOverflow"
};
export default ___CSS_LOADER_EXPORT___;
