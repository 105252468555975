import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch/Switch';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import PublishIcon from '@material-ui/icons/Publish';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import VisibilityIcon from '@material-ui/icons/Visibility';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { redirect, useLoaderData } from 'react-router-dom';
import { useNavigateWithParams } from 'utils/url';

import StatusFilter from 'components/button/StatusFilter';
import UploadButton from 'components/button/UploadButton';
import LoadingIndicator from 'components/LoadingIndicator';
import MultiColumnMenu from 'components/Menu/MultiColumnMenu';
import PageHeader from 'components/PageHeader';
import { columnKey } from 'components/ReactTable';
import { Material } from 'modules/material';
import { PARAMETERS } from 'modules/scenario';
import { mergeClasses } from 'utils/classHelper';

import InlineEdit from './InlineEdit';
import Panel from './Panel';

import Table, { columns, defaultColumns, defaultMaterialColumns } from './Table';
import styles from './styles.scss';
import { calculateComparison, getComparison, updateComparison } from 'modules/api2/comparison';
import { resetScenario, updateScenario } from 'modules/api2/scenario';
import { bulkUpdate } from './bulkUpdate';
import { useStoreContext } from 'contexts/StoreContext';
import { useDataContext } from 'contexts/DataContext';
import { addBase, getEditedColumns } from './preprocessing';
import { useToggle, useToggleAll } from 'components/ReactTable/toggleColumns';

dayjs.extend(duration);
const GET_COMPARISON_PARAMS = { withMaterials: true, reducedCalculations: true };

export async function loader({ params, request }) {
	const url = new URL(request.url);
	const dashboardView = url.searchParams.get('dashboardView');
	const comparison = await getComparison(params.comparisonId, { ...GET_COMPARISON_PARAMS, view: dashboardView });
	if (comparison.status === 'FINISHED') return redirect(`/simulator/${params.comparisonId}`);
	const comparisonWithBase = addBase(comparison);
	return { comparison: comparisonWithBase };
}

export function SimulatorEditionPage() {
	const { comparison: loaderComparison } = useLoaderData();
	const [comparison, setComparison] = useState(loaderComparison);

	const { features } = useStoreContext();
	const editedColumns = getEditedColumns(comparison);
	const materials = comparison.scenarios ? comparison.scenarios[0].materials : [];

	const [open, setOpen] = useState(false);
	const [selected, setSelected] = useState([]);
	const [selectedMaterials, setSelectedMaterials] = useState([]);
	const [editedMaterial, setEditedMaterial] = useState({});
	const [filtered, setFiltered] = useState([]);
	const [toggled, setToggled] = useState(defaultColumns);
	const [sorted, setSorted] = useState([{ id: 'number', desc: false }]);
	const [loading, setLoading] = useState(false);
	const [canEdit, setCanEdit] = useState(true);

	const { dashboardView, updateDataContext } = useDataContext();

	const navigate = useNavigateWithParams();
	const toggle = useToggle(toggled, setToggled);
	const toggleAll = useToggleAll(toggled, setToggled);
	const resetColumns = () => setToggled(editedColumns.length ? [...defaultMaterialColumns, ...editedColumns] : defaultColumns);

	const refreshComparison = async () => {
		const updatedComparison = await getComparison(comparison.id, { ...GET_COMPARISON_PARAMS, view: dashboardView });
		setComparison(addBase(updatedComparison));
	};

	// Update toggle state for each column
	columns.forEach(c => c.columns && c.columns.forEach(m => (m.show = toggled.indexOf(columnKey(m)) > -1))); // eslint-disable-line no-return-assign, no-param-reassign

	useEffect(() => setToggled(editedColumns.length ? [...defaultMaterialColumns, ...editedColumns] : defaultColumns),
		[comparison.id]);

	useEffect(() => setComparison(loaderComparison), [loaderComparison]);

	useEffect(() => {
		if (comparison.status !== 'DRAFT') {
			const intervalId = setInterval(refreshComparison, 1000);
			return () => clearInterval(intervalId);
		}
	}, [comparison]);


	useEffect(() => {
		// set the editing state (table greyed out or not)
		setCanEdit(!loading && comparison.status === 'DRAFT');
	}, [loading, comparison.status]);

	document.title = `${comparison.description} - Simulation edition`;

	useEffect(() => {
		if (materials) {
			const newSelectedMaterials = materials.filter(m => selected.includes(m.id));
			setSelectedMaterials(newSelectedMaterials);
			const mat = { ...newSelectedMaterials[0] };
			newSelectedMaterials.forEach(m => {
				Object.entries(m).forEach(([k, v]) => {
					if (m[k] !== mat[k]) mat[k] = '(mixed)';
				});
			});
			setEditedMaterial(mat);
		}
	}, [selected]);

	const count = materials.filter(m => m.edited).length;

	const eta = dayjs.duration(count * 10, 'seconds'); // 3 minutes per material
	const etaHours = Math.floor(eta.asHours());
	let etaString;
	if (etaHours > 2) {
		etaString = `${etaHours}:${Math.round(eta.asMinutes() % 60)} hours`;
	} else if (eta.asHours() > 1) {
		etaString = `${etaHours}:${Math.round(eta.asMinutes() % 60)} hour`;
	} else if (eta.asMinutes() > 1) {
		etaString = `${Math.round(eta.asMinutes())} min.`;
	} else {
		etaString = `${Math.round(eta.asSeconds())} sec.`;
	}

	const isFilterActive = filterName => _.findIndex(filtered, { id: filterName, value: true }) > -1;
	const toggleFilter = filterName => {
		const newFilters = _.slice(filtered);
		const index = _.findIndex(newFilters, {
			id: filterName,
			value: true
		});
		if (index > -1) {
			newFilters.splice(index, 1);
		} else {
			newFilters.push({ id: filterName, value: true });
		}
		setFiltered(newFilters);
	};

	return (
		<>
			{loading && <LoadingIndicator />}
			<PageHeader
				backTo="/simulator"
				right={
					<Grid container alignItems="center">
						<Grid item className={styles.Count}>
							{count}
						</Grid>
						<Grid item>
							Materials are included <br />
							in simulation
						</Grid>
						<Grid item className={styles.Timer}>
							{etaString}
						</Grid>
						<Grid item>
							<div>
								Estimated time <br /> to run calculations
							</div>
						</Grid>
						<Grid item className={styles.Button}>
							<Button
								color="primary"
								onClick={() => {
									calculateComparison(comparison.id);
									navigate(`/simulator`, { state: { refresh: true } });
								}}
								size="large"
								startIcon={<PlayCircleOutlineIcon />}
								variant="contained">
								Run calculations
							</Button>
						</Grid>
					</Grid>
				}
				secondLine={
					<Grid container alignItems="center" spacing={2}>
						<Grid item style={{ flexGrow: 1 }}>
							<StatusFilter
								startIcon={<VisibilityIcon fontSize="small" />}
								onChange={() => toggleFilter('edited')}
								selected={isFilterActive('edited')}>
								Edited materials ({materials.filter(m => m.edited).length})
							</StatusFilter>
						</Grid>
						{features.fullDashboard && (
							<Grid item>
								<div>
									<FormControlLabel
										label="All materials"
										control={
											<Switch
												checked={Boolean(dashboardView)}
												onChange={(event, value) => {
													updateDataContext('dashboardView', value ? features.fullDashboard : null);
												}}
											/>
										}
									/>
								</div>
							</Grid>
						)}
						<Grid item>
							<ButtonGroup>
								<Button onClick={async () => {
									setLoading(true);
									await resetScenario(comparison.scenarios[0].id);
									await refreshComparison();
									setLoading(false);
								}} startIcon={<SettingsBackupRestoreIcon />}>
									Reset
								</Button>
								<MultiColumnMenu columns={columns} onSelect={toggle} onSelectAll={toggleAll} reset={resetColumns}>
									Columns
								</MultiColumnMenu>
								<UploadButton onChange={async (e) => {
									setLoading(true);
									await bulkUpdate({ comparison, updateScenario })(e, materials);
									await refreshComparison();
									setLoading(false);
								}} startIcon={<PublishIcon />}>
									Batch update
								</UploadButton>
							</ButtonGroup>
						</Grid>
					</Grid>
				}>
				<InlineEdit value={comparison.description}
										onBlur={description => updateComparison(comparison.id, { description })}
				/>
			</PageHeader>
			<div className={mergeClasses(styles.Body, canEdit ? '' : styles.NoEdit)}>
				<Panel
					count={selected.length}
					material={editedMaterial}
					open={canEdit && open}
					onSave={async (material, { touched }) => {
						const materialsToUpdate = selectedMaterials.map(m => {
							const modMaterial = { id: m.id };
							PARAMETERS.forEach(p => {
								const fieldConfig = Material[p];
								// FIXME Touched is not set when pressing enter
								if (p in touched && material[p] !== '(mixed)' && material[p] !== null) {
									// Check if material[p] is a boolean
									if (fieldConfig.type === 'number') {
										modMaterial[p] = parseFloat(material[p]); // eslint-disable-line no-param-reassign
									} else {
										modMaterial[p] = material[p];
									}
								}
								const percentKey = `${p}__percent`;
								// FIXME Touched is not set when pressing enter
								if (touched[percentKey] && _.isNumber(m[p]) && material[percentKey]) {
									modMaterial[p] = m[p] * (1 + parseFloat(material[percentKey]) / 100); // eslint-disable-line no-param-reassign
								}
							});
							return modMaterial;
						});
						setLoading(true);
						await updateScenario(comparison.scenarios[0].id, materialsToUpdate);
						await refreshComparison();
						setLoading(false);
						setOpen(false);
						setSelected([]);
						setLoading(false);

					}}
					onCancel={() => {
						setOpen(false);
						setSelected([]);
					}}
					toggled={toggled}
					toggle={toggle}
					toggleAll={toggleAll}
				/>
				<Table
					data={materials}
					filtered={filtered}
					onClick={newSelected => setOpen(newSelected.length > 0)}
					onSortedChange={sorted => setSorted(sorted)}
					selected={selected}
					onFilteredChange={setFiltered}
					setSelected={row => canEdit && setSelected(row)}
					setToggled={setToggled}
					sorted={sorted}
					toggle={toggle}
					toggleAll={toggleAll}
					toggled={toggled}
				/>
			</div>
		</>
	);
}


export default SimulatorEditionPage;
