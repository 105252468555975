/**
 * Opportunities table selectors
 */

import { createSelector } from 'reselect';
import { initialState } from './reducer';

export const selectOrdersList = state => state.get('Orders') || initialState;

export const selectFilters = () =>
	createSelector(
		selectOrdersList,
		state => state.filters
	);

export const selectQuickFilters = () =>
	createSelector(
		selectOrdersList,
		state => state.quickFilters
	);

export const selectSortedColumns = () =>
	createSelector(
		selectOrdersList,
		state => state.sortedColumns
	);

export const selectToggledColumns = () =>
	createSelector(
		selectOrdersList,
		state => state.toggledColumns
	);

export const selectPage = () =>
	createSelector(
		selectOrdersList,
		state => state.page
	);

export const selectPageSize = () =>
	createSelector(
		selectOrdersList,
		state => state.pageSize
	);

export const selectFilteredColumns = () =>
	createSelector(
		selectOrdersList,
		state => state.filteredColumns
	);
