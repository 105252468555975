/**
 *
 * ButtonBanner
 *
 */

import Button from '@material-ui/core/Button';
import React from 'react';

import { mergeClasses } from 'utils/classHelper';
import styles from './styles.scss';

const XSButton = ({ className, ...rest }) => <Button className={mergeClasses(className, styles.button)} size="small" {...rest} />;

export default XSButton;
