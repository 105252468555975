import Box from '@material-ui/core/Box';
import React from 'react';
import { mergeClasses } from 'utils/classHelper';
import styles from './styles.scss';

export default ({ children, value, index, noOverflow, spacing, ...rest }) => (
	<div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...rest}>
		{value === index && (
			<Box className={mergeClasses(styles.TabPanel, !noOverflow ? styles.NoOverflow : null)}>
				<div>{children}</div>
			</Box>
		)}
	</div>
);
