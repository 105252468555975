import { columnKey } from './index';

export const useToggle = (toggled, setToggled) => col => {
	const key = columnKey(col);
	setToggled(toggled.includes(key) ? toggled.filter(k => k !== key) : [...toggled, key]);
};

export const useToggleAll = (toggled, setToggled) => oColumns => {
	const keys = oColumns.map(columnKey);
	const allToggled = keys.every(key => toggled.includes(key));
	setToggled(allToggled ? toggled.filter(key => !keys.includes(key)) : [...new Set([...toggled, ...keys])]);
};
