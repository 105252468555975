// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-components-button-XSButton-styles-button{min-width:unset !important;padding:4px 5px !important}", "",{"version":3,"sources":["webpack://./app/components/button/XSButton/styles.scss"],"names":[],"mappings":"AACA,8CACC,0BAAA,CACA,0BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "app-components-button-XSButton-styles-button"
};
export default ___CSS_LOADER_EXPORT___;
