import React, { useEffect } from 'react';
import { Link, useLoaderData, useLocation, useSearchParams } from 'react-router-dom';
import { useNavigateWithParams } from 'utils/url';
import LoadingIndicator from 'components/LoadingIndicator';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ShareIcon from '@material-ui/icons/Share';
import PageHeader from 'components/PageHeader';
import { comparisonGenericUrl, comparisonUrl } from 'modules/routing/simulator';
import { mergeClasses } from 'utils/classHelper';
import { daysToHorizon, formatDate, frequencyToUnit } from 'utils/dates';
import { capitalize, formatEmail } from 'utils/format';
import InlineEdit from '../SimulatorEditionPage/InlineEdit';
import styles from './styles.scss';
import {
	copyComparison,
	createComparison,
	deleteComparison,
	interruptComparison,
	listComparisons,
	updateComparison
} from 'modules/api2/comparison';
import { copyToClipboard } from 'utils/copyToClipboard';

export function SimulatorPage() {
	document.title = 'Simulator - GenLots';
	const navigate = useNavigateWithParams();
	const location = useLocation();
	const [searchParams] = useSearchParams();
	const { comparisonList: initialComparisonList } = useLoaderData();
	const [comparisonList, setComparisonList] = React.useState(initialComparisonList);
	const [loading, setLoading] = React.useState(false);

	const company = searchParams.get('company');

	const refreshComparisonList = async () => {
		const updatedList = await listComparisons(company);
		setComparisonList(updatedList);
	};

	// refresh the comparison list every 2 seconds
	useEffect(() => {
		const intervalId = setInterval(refreshComparisonList, 2000);
		return () => clearInterval(intervalId);
	}, [company]);

	useEffect(() => {
		setLoading(true);
		refreshComparisonList();
		setLoading(false);
	}, [company]);

	useEffect(() => {
		// make sure that you can refresh the list if you come from the simulator edition page
		if (location.state && location.state.refresh) {
			setLoading(true);
			refreshComparisonList();
			navigate(location.pathname); //clean the state
			setLoading(false);
		}
	}, [location.state]);

	return (
		<div className={styles.Body}>
			{loading && <LoadingIndicator />}
			<PageHeader
				right={
					<Button
						className={styles.CreateButton}
						size="large"
						variant="contained"
						color="primary"
						onClick={async () => {
							const { id } = await createComparison(company);
							navigate(`${id}`);
						}}
						startIcon={<AddIcon />}>
						Create simulation
					</Button>
				}>
				<h1>Simulations</h1>
			</PageHeader>
			{comparisonList.map(comp => (
				<Link to={comparisonUrl(comp, '?' + searchParams.toString())} key={comp.id} className={styles.Comparison}>
					<div>
						<div className={styles.Title}>
							<InlineEdit
								value={comp.description}
								onBlur={async (description) => await updateComparison(comp.id, { description })}
							/>
							<div>{formatDate(comp.creationDate)}</div>
						</div>
						<div className={styles.CreatedBy}>
							<div>Created by</div>
							<div>{formatEmail(comp.createdBy.email || 'Unknown user')}</div>
						</div>
						<div className={styles.Field}>
							<div>Start date</div>
							<div>{comp.status !== 'INIT' ? formatDate(comp.importDate) : '-'}</div>
						</div>
						<div className={styles.Field}>
							<div>Horizon</div>
							<div>
								{daysToHorizon(comp.horizon, comp.frequency)} {frequencyToUnit(comp.frequency)}
							</div>
						</div>
						<div className={styles.Material}>
							<div>{comp.status !== 'INIT' ? comp.numMaterials : '-'}</div>
							<div>Materials</div>
						</div>
						<div className={styles.Status}>
							<ComparisonProgress comparison={comp} onCancel={interruptComparison} />
						</div>
						<div className={styles.Actions}>
							<Button
								variant="outlined"
								startIcon={<FileCopyIcon />}
								onClick={async (e) => {
									e.stopPropagation();
									e.preventDefault();
									const compCopy = await copyComparison(comp.id);
									navigate(`${compCopy.id}`);
								}}>
								Copy
							</Button>
							<Button
								variant="outlined"
								startIcon={<ShareIcon />}
								onClick={e => {
									e.stopPropagation();
									e.preventDefault();
									copyToClipboard(window.location.host + comparisonGenericUrl(comp, '?' + searchParams.toString()));
								}}>
								Share
							</Button>
							<IconButton
								aria-label="delete"
								onClick={async (e) => {
									e.stopPropagation();
									e.preventDefault();
									setLoading(true);
									await deleteComparison(comp.id);
									refreshComparisonList();
									setLoading(false);
								}}>
								<DeleteIcon />
							</IconButton>
						</div>
					</div>
				</Link>
			))}
		</div>
	);
}

function ComparisonProgress({ comparison, onCancel }) {
	const val2Progress = {
		CANCELLED: 100,
		DRAFT: 0,
		FINISHED: 100,
		FAILED: 100
	};
	const val2Class = {
		CANCELLED: styles.Cancelled,
		FINISHED: styles.Finished,
		FAILED: styles.Failed
	};
	const val2Text = {
		INIT: 'Creating'
	};
	const val2Variant = {
		INIT: 'indeterminate',
		'IN PROGRESS': 'indeterminate'
	};
	return (
		<>
			{val2Text[comparison.status] || capitalize(comparison.status, true)}
			<LinearProgress
				className={mergeClasses(styles.Progress, val2Class[comparison.status])}
				value={val2Progress[comparison.status]}
				variant={val2Variant[comparison.status] || 'determinate'}
			/>
			<div className={styles.Cancel}>
				{comparison.status === 'IN PROGRESS' && (
					<Button
						onClick={e => {
							e.stopPropagation();
							onCancel(comparison);
						}}
						variant="outlined"
						size="small">
						Stop
					</Button>
				)}
			</div>
		</>
	);
}

export default SimulatorPage;
