export const SET_FILTERS = 'SET_FILTERS';
export const setFilters = newFilters => ({
	type: SET_FILTERS,
	payload: newFilters
});

export const SET_QUICK_FILTERS = 'SET_QUICK_FILTERS';
export const setQuickFilters = newQuickFilters => ({
	type: SET_QUICK_FILTERS,
	payload: newQuickFilters
});

export const SET_TOGGLED_COLUMNS = 'SET_TOGGLED_COLUMNS';
export const setToggledColumns = newColumns => ({
	type: SET_TOGGLED_COLUMNS,
	payload: newColumns
});

export const SET_SORTED_COLUMNS = 'SET_SORTED_COLUMNS';
export const setSortedColumns = newSort => ({
	type: SET_SORTED_COLUMNS,
	payload: newSort
});

export const SET_PAGE = 'SET_PAGE';
export const setPage = newPage => ({
	type: SET_PAGE,
	payload: newPage
});

export const SET_PAGE_SIZE = 'SET_PAGE_SIZE';
export const setPageSize = newPageSize => ({
	type: SET_PAGE_SIZE,
	payload: newPageSize
});

export const SET_FILTERED_COLUMNS = 'SET_FILTERED_COLUMNS';
export const setFilteredColumns = newFiltered => ({
	type: SET_FILTERED_COLUMNS,
	payload: newFiltered
});
