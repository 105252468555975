// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-pages-OrdersPage-styles-FullDashboardSwitch{vertical-align:text-bottom !important}.app-pages-OrdersPage-styles-FullDashboardSwitch>span{font-size:13px}.app-pages-OrdersPage-styles-Opening button{transition:.2s}.app-pages-OrdersPage-styles-Opening:hover button{opacity:1}.app-pages-OrdersPage-styles-Opening .app-pages-OrdersPage-styles-Validated{margin-left:5px;color:#3b9173}", "",{"version":3,"sources":["webpack://./app/pages/OrdersPage/styles.scss","webpack://./app/style/variables.scss"],"names":[],"mappings":"AACA,iDACC,qCAAA,CAEA,sDACC,cAAA,CAKD,4CACC,cAAA,CAGD,kDACC,SAAA,CAGD,4EACC,eAAA,CACA,aCEM","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FullDashboardSwitch": "app-pages-OrdersPage-styles-FullDashboardSwitch",
	"Opening": "app-pages-OrdersPage-styles-Opening",
	"Validated": "app-pages-OrdersPage-styles-Validated"
};
export default ___CSS_LOADER_EXPORT___;
